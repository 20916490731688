<template>
  <div class="col building_the_team_wrapper just_label_in_cards_override">
    <!-- Start Why this is important -->
    <card>
      <div class="importance_wrapper">
        <h4
          :class="{ 'text-right': isRtl() }"
          v-if="team_pages_titles_content[1]"
          v-html="team_pages_titles_content[1].body"
        ></h4>
        <div
          :class="{ 'text-right': isRtl() }"
          v-if="team_pages_content[1]"
          v-html="team_pages_content[1].body"
        ></div>
      </div>
    </card>
    <!-- End Why this is important  -->

    <!-- Start Team Card  -->
    <card>
      <template #header>
        <div
          class="header_contact_wrapping mb-4"
          :class="{ 'd-flex flex-row-reverse': isRtl() }"
        >
          <h4
            class="card-title"
            :class="{ 'd-flex flex-row-reverse': isRtl() }"
          >
            <i class="fa fa-users team_icon"></i>
            <span :class="{ 'mr-2': isRtl() }">{{
              localization("Build New Team")
            }}</span>
          </h4>
        </div>
        <!-- Start Team Form  -->
        <el-form
          :model="teamForm"
          :rules="rules"
          ref="addTeam"
          :class="{ 'text-right build_with_rtl': isRtl() }"
        >
          <el-row class="pt-2" :gutter="20">
            <el-col :sm="24">
              <el-row
                class="pt-2"
                :gutter="20"
                :class="{ 'd-flex flex-row-reverse': isRtl() }"
              >
                <el-col :sm="12" class="my-2">
                  <el-form-item
                    :label="`${localization('Give your team a name')}`"
                    prop="name"
                  >
                    <el-input
                      :placeholder="`${localization('Write Name')}`"
                      v-model="teamForm.name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" class="my-2">
                  <el-form-item :label="`${localization('Add team members')}`">
                    <el-select
                      ref="users"
                      :placeholder="`${localization('Select Members')}`"
                      :no-data-text="`${localization('Write Name')}`"
                      style="width: 100%"
                      v-model="teamForm.selected_team_members"
                      filterable
                      multiple
                      remote
                      reserve-keyword
                      :remote-method="search"
                      :loading="loadingValue"
                    >
                      <el-option
                        v-for="user in contactList"
                        :key="user.id"
                        :label="user.first_name + ' ' + user.last_name"
                        :value="user.id"
                        :disabled="teamForm.selected_team_members.length == 5"
                      >
                        <span style="color: #8492a6; font-size: 13px">
                          <i class="el-icon-user-solid"></i>
                          {{ user.first_name + " " + user.last_name }}
                        </span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- institution Users List -->
                <el-col :sm="12">
                  <el-form-item label="Institution Users" prop="">
                    <el-select
                      v-model="teamForm.selected_institutions_users"
                      :placeholder="`${localization('Please select user')}`"
                      style="width: 100%"
                      multiple
                      filterable
                    >
                      <el-option
                        v-for="item in institutionUsersList"
                        :key="item.id"
                        :label="item.first_name + ' ' + item.last_name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- institution Users List -->
              </el-row>
            </el-col>
            <el-col :sm="24" class="my-2">
              <div>{{ localization("Reinvite members by email") }}</div>
            </el-col>
            <el-col :sm="24">
              <el-row
                class="pt-2"
                :gutter="20"
                :class="{ 'd-flex flex-row-reverse': isRtl() }"
              >
                <el-col :sm="12" class="my-2">
                  <el-form-item label="Email" style="width: 100%" prop="emails">
                    <el-select
                      v-model="teamForm.emails"
                      :no-data-text="`${localization('Type Email')}`"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      class=""
                      style="width: 100%"
                      :placeholder="`${localization('Type Email')}`"
                    >
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24">
              <div
                class="dialog_footer"
                :class="{ 'justify-content-start': isRtl() }"
              >
                <el-button
                  class="text-center text-capitalize save_button"
                  @click="save_team"
                >
                  {{ localization("Add Team") }}</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-form>
        <!-- End Team Form  -->

        <!-- Start Chart  -->
        <div>
          <div
            class="dialog_footer show_line_graph_btn"
            :class="{ 'justify-content-end': isRtl() }"
          >
            <el-button
              class="text-center text-capitalize save_button "
              @click="teamLineGraphHandler"
              :disabled="teamForm.selected_team_members.length < 1"
            >
              {{ localization("Show Team Line Graph") }}</el-button
            >
          </div>

          <div v-if="line_graph_members.length >= 1">
            <div class="the_main_wrapper_for_charts" v-if="!loadingValue">
              <div class="is_chart_wrapper">
                <chart-card
                  v-if="scores.length > 0"
                  :chart-data="teamChart"
                  :chart-options="barChart.options"
                  :chart-responsive-options="barChart.responsiveOptions"
                >
                  <template slot="header">
                    <div
                      class="d-flex"
                      :class="{ 'flex-row-reverse': isRtl() }"
                    >
                      <i
                        class="el-icon el-icon-data-analysis"
                        style="font-size: 30px; margin-right: 7px"
                      ></i>
                      <h4 class="card-title">
                        {{ localization("Team Line Graph") }}
                      </h4>
                    </div>
                  </template>
                </chart-card>
                <div class="lines_in_chart avoid">
                  <span class="is_text_chart">Avoid</span>
                </div>
                <div class="lines_in_chart needed">
                  <span class="is_text_chart">As Needed</span>
                </div>
                <div class="lines_in_chart first">
                  <span class="is_text_chart">Use First</span>
                </div>
              </div>
              <div
                class="for_line_graph_members_container"
                :class="{ for_line_graph_members_with_rtl: isRtl() }"
              >
                <div
                  v-for="(member, index) in line_graph_members"
                  :key="member.id"
                  class="every_bullet_wrapper"
                  :class="`member_${index + 1}`"
                >
                  <span class="bullet"></span>
                  <span class="text-capitalize"
                    >{{ member.first_name }} {{ member.last_name }}
                  </span>
                </div>
              </div>
              <div class="text-center team_description_text">
                <p>{{ line_graph_members_description }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- End Chart  -->
      </template>
      <div
        class="team_routes_wrapper"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <router-link class="back_link" to="/teams"
          ><i class="fa fa-arrow-left back_icon mr-1"></i>
          {{ localization("Back to team") }}
        </router-link>
        <router-link class="back_link" to="/working-together">
          {{ localization("next") }}
          <i class="fa fa-arrow-right back_icon mr-1"></i>
        </router-link>
      </div>
    </card>
    <!-- End Team Card  -->
  </div>
</template>

<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
export default {
  data() {
    return {
      teamForm: {
        name: "",
        selected_team_members: [],
        emails: [],
        selected_institutions_users: []
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      barChart: {
        options: {
          seriesBarDistance: 20,
          showPoint: true,
          labelDirection: "explode",
          chartPadding: 0,
          labelOffset: 20,
          // distributeSeries: true,
          // steps: 5,
          low: 7,
          high: 40,
          // lineSmooth: true,

          showLine: true,

          labelInterpolationFnc: function(value) {
            return `${value.split(" ")[0]}...`;
          },
          height: "350px",
          width: "95%",
          axisX: {
            offset: 30,
            position: "end",
            showLabel: true,
            showGrid: true,
            labelOffset: {
              x: 0,
              y: 5
            }
          },
          axisY: {
            offset: 50,
            position: "start",
            showLabel: true,
            showGrid: true,
            scaleMinSpace: 20,
            onlyInteger: true,
            labelOffset: {
              x: 0,
              y: 0
            }
          }
          // plugins: [Chartist.plugins.tooltip()]
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      }
    };
  },

  components: {
    Card,
    ChartCard,
    StatsCard
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      contactList: ["contacts/contactList"],
      line_graph_members: ["user/line_graph_members"],
      line_graph_members_description: ["user/line_graph_members_description"],
      team_pages_content: ["user/get_team_pages_content"],
      team_pages_titles_content: ["user/get_team_pages_titles_content"],
      institutionUsersList: ["groups/getInstUsers"]
    }),
    scores() {
      let scores = [];
      this.line_graph_members.forEach(element => {
        scores.push([
          element.sequence,
          element.precision,
          element.technical_reasoning,
          element.confluence
        ]);
      });
      return scores;
    },
    teamChart() {
      let data = {};

      data.labels = [
        "Sequence",
        "Precision",
        "Technical Reasoning",
        "Confluence"
      ];
      data.series = this.scores;
      return data;
    }
  },
  mounted() {
    this.getContactsList();
    this.$store.dispatch("user/get_all_teams_content");
    this.$store.dispatch("groups/institutionUsersGroup");
  },
  methods: {
    getContactsList(query) {
      this.$store.dispatch("contacts/GetContactsList", { query: query });
    },
    search(query) {
      this.usersRoles(query);
    },

    usersRoles(search = "", limit = 10) {
      this.$store
        .dispatch("contacts/GetContactsList", {
          search_text: search
        })
        .then(() => {
          this.search_group = this.$store.getters["contacts/contactList"];
        });
    },
    teamLineGraphHandler() {
      this.$store.dispatch("user/get_team_line_graph", {
        users: [...this.teamForm.selected_team_members, this.auth().id]
      });
    },
    teamLineGraphHandlerForEmptyData() {
      this.$store.dispatch("user/get_team_line_graph", {
        users: this.teamForm.selected_team_members
      });
    },
    save_team() {
      this.$refs["addTeam"].validate(valid => {
        if (valid) {
          let selectedMem = [
            ...this.teamForm.selected_team_members,
            ...this.teamForm.selected_institutions_users
          ];
          this.$store
            .dispatch("user/add_team", {
              name: this.teamForm.name,
              emails: this.teamForm.emails,
              users: selectedMem
              // users: this.teamForm.selected_team_members
            })
            .then(response => {
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              }).then(data => {
                if (data.isConfirmed) {
                  this.teamForm.name = "";
                  this.teamForm.emails = [];
                  this.teamForm.selected_team_members = [];
                  this.teamForm.selected_institutions_users = [];
                  this.teamLineGraphHandlerForEmptyData();
                  this.$router.push(`/teams/${response.team.id}`);
                }
              });
            });
        } else {
          console.log("test");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.building_the_team_wrapper {
  .team_routes_wrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    .back_link {
      color: #777;
      background-color: #eee;
      padding: 8px 10px;
      display: block;
      width: fit-content;
      margin: 10px 0;
      border-radius: 8px;
      transition: 0.3s;
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
  }
  .importance_wrapper {
    color: #777;
    margin-bottom: 3rem;
    h4 {
      margin-top: 10px;
    }
    ol {
      li {
        margin-bottom: 10px;
        line-height: 1.7;
      }
    }
  }

  // Start Header
  .header_contact_wrapping {
    .card-title {
      color: #777;
      .team_icon {
        color: #23ccef;
        margin-right: 10px;
      }
    }
  }
  .dialog_footer {
    display: flex;
    justify-content: end;
    align-items: center;
    &.show_line_graph_btn {
      justify-content: start;
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      padding: 15px 0;
      margin-top: 15px;
    }
    .save_button {
      background-color: #23ccef;
      border-color: #23ccef;
      min-width: 150px;
      color: #fff;
    }
  }

  .the_main_wrapper_for_charts {
    background: #fff;
    padding-bottom: 1rem;
    border: 1px solid rgba(#000, 0.125);
    .card {
      border-bottom: transparent !important;
      margin-bottom: 0;
    }
    .card-body {
      // border: transparent !important;
    }
    .description_team {
      background-color: #fff;
      padding: 0 0.5rem;
      text-align: center;
      .team_type {
        color: #777;
        margin: 0;
        line-height: 1.7;
        font-size: 17px;
      }
    }
    .team_description_text {
      color: #777;
      margin: 0;
      line-height: 1.7;
      font-size: 17px;
    }
    .is_chart_wrapper {
      position: relative;
    }
    .lines_in_chart {
      position: relative;
      bottom: 240px;
      padding: 0 5px;
      &::before {
        position: absolute;
        content: "";
        width: 87%;
        left: 5%;
        bottom: 0;
        border: 1px dashed #11bfe3;
      }
      &.avoid {
        &::before {
          bottom: -50px;
        }
        .is_text_chart {
          position: absolute;
          bottom: -105px;
          font-size: 12px;
        }
      }
      &.needed {
        bottom: 324px;
        &::before {
          bottom: -75px;
        }
        .is_text_chart {
          position: absolute;
          bottom: -100px;
          font-size: 12px;
        }
      }
      &.first {
        bottom: 443px;
        &::before {
          display: none;
        }
        .is_text_chart {
          position: absolute;
          bottom: -120px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.building_the_team_wrapper {
  .card .ct-label.ct-vertical.ct-start {
    width: 38px !important;
  }
  .for_line_graph_members_container {
    display: flex;
    // justify-content: space-between;
    gap: 15px;
    align-items: center;
    padding: 0 15px 0;
    flex-wrap: wrap;
    .every_bullet_wrapper {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-bottom: 20px;
      .bullet {
        background: #eee;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      &.member_1 {
        .bullet {
          background-color: black;
        }
      }
      &.member_2 {
        .bullet {
          background-color: #fe9d52;
        }
      }
      &.member_3 {
        .bullet {
          background-color: #4198d7;
        }
      }
      &.member_4 {
        .bullet {
          background-color: brown;
        }
      }
      &.member_5 {
        .bullet {
          background-color: #7a61ba;
        }
      }
    }
    &.for_line_graph_members_with_rtl {
      flex-direction: row-reverse;
      .every_bullet_wrapper {
        flex-direction: row-reverse;
      }
    }
  }
  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: black;
  }
  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: #fe9d52;
  }
  .ct-series-c .ct-line,
  .ct-series-c .ct-point {
    stroke: #4198d7;
  }
  .ct-series-d .ct-line,
  .ct-series-d .ct-point {
    stroke: brown;
  }
  .ct-series-e .ct-line,
  .ct-series-e .ct-point {
    stroke: #7a61ba;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .ct-label.ct-horizontal.ct-end {
      font-size: 11px !important;
    }
  }
  .is_chart_wrapper {
    .ct-chart-line {
      width: 90% !important;
    }
  }
  .build_with_rtl {
    .el-form-item__label {
      width: 100%;
    }
    .el-input__inner {
      text-align: right;
    }
  }
}

.just_label_in_cards_override {
  .card label {
    text-transform: capitalize;
  }
}
</style>
